<template>
    <div
        class="editor field tw-h-full tw-relative"
        :class="{
            'editor--disabled': disabled,
            'editor--invalid field--invalid': invalid,
        }"
        :style="styles"
    >
        <div class="tw-hidden">
            <font-awesome-icon ref="insertTable" icon="table" size="1x" class="tw-cursor-pointer" />
        </div>
        <div class="qeditor-wrapper">
            <quill-editor :disabled="disabled" :id="editorId" v-model="contentComputed" ref="myQuillEditor" :options="editorConfig" @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @ready="onEditorReady($event)" />
        </div>
    </div>
</template>

<script>
// TODO optimize component: remove extra functions

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import {quillEditor, Quill} from "@/lib/vue-quill-editor";
import {ImageExtend /* , QuillWatch */} from "quill-image-extend-module";

// import Delta from "quill-delta";
import axios from "@/axios";
import QuillBetterTable from "quill-better-table";

Quill.register("modules/ImageExtend", ImageExtend);

Quill.register(
    {
        "modules/better-table": QuillBetterTable,
    },
    true
);

export default {
    name: "rich-text-editor",
    components: {
        quillEditor,
    },
    props: {
        value: {
            type: String,
            default: () => "",
        },
        placeholder: {
            type: String,
            default: () => "Enter description here",
        },
        styles: {
            type: Object,
            default: () => ({}),
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
        invalid: {
            type: Boolean,
            default: () => false,
        },
        // unique name for creating editor instance
        name: {
            type: String,
            default: () => "editor",
        },
    },
    data() {
        return {
            content: "",
            // activeTemplate: null,
            uploadedUrl: null,
            table: null,
            editorOption: {
                placeholder: this.placeholder,
                modules: {
                    // table: false,  // disable table module
                    "better-table": {
                        operationMenu: {
                            items: {
                                // unmergeCells: {
                                //   text: 'Another unmerge cells name'
                                // }
                            },
                            color: {
                                colors: ["#fff", "red", "#d5e1df", "#e3eaa7", " #b5e7a0", "#eea29a", "#80ced6", "#f7786b", "#f4e1d2"], // colors in operationMenu
                                text: "Background Colors", // subtitle
                            },
                        },
                    },

                    // see: https://github.com/NextBoy/quill-image-extend-module#quill-image-extend-module-%E7%9A%84%E6%89%80%E6%9C%89%E5%8F%AF%E9%85%8D%E7%BD%AE%E9%A1%B9
                    ImageExtend: {
                        loading: true,
                        name: "img",
                        action: "https://jsonplaceholder.typicode.com/posts/",
                        headers: (xhr) => {
                            xhr.setRequestHeader("withCredentials", true);
                        },
                        response: (res) => {
                            return res.info;
                        },
                    },
                    // see: https://quilljs.com/docs/modules/toolbar/
                    toolbar: {
                        container: [[{size: ["small", false, "large", "huge"]}, {header: [1, 2, 3, 4, 5, 6, false]}], ["bold", "italic", "underline", "strike", {color: []}, {background: []}], [{list: "ordered"}, {list: "bullet"}, {indent: "-1"}, {indent: "+1"}], ["link", "image"], ["clean"], ["insertTable"]],
                        handlers: {
                            image: this.selectLocalImage,
                            insertTable: () => this.table.insertTable(3, 3),

                            // image: function() {
                            //   QuillWatch.emit(this.quill.id);
                            // }
                        },
                    },
                    keyboard: {
                        bindings: QuillBetterTable.keyboardBindings,
                    },
                },
            },
            onEditorBlur() {},
            onEditorFocus() {},
            onEditorReady(editor) {
                if (!window.editor) window.editor = {};
                window.editor[this.name] = editor;
                this.table = window.editor[this.name].getModule("better-table");
                editor.clipboard.dangerouslyPasteHTML(this.value, "silent");

                // TODO should be optimized
                const toolbar = editor.getModule("toolbar").container;
                const insertTableBtn = toolbar.querySelector(".ql-insertTable");
                const btnIcon = this.$refs.insertTable.cloneNode(true);
                insertTableBtn.appendChild(btnIcon);
            },
        };
    },
    computed: {
        // * used for value changes handling because of errors in quill props handlers
        contentComputed: {
            get() {
                return this.content;
            },
            set(newValue) {
                this.$emit("input", newValue);
            },
        },
        editor() {
            return this.$refs.myQuillEditor;
        },
        editorId() {
            return `ql-editor--${this.name}`;
        },
        editorConfig() {
            return {
                ...this.editorOption,
                bounds: "#" + this.editorId,
            };
        },
    },
    // watch: {
    //     value(newValue) {
    //         window.editor[this.name].clipboard.dangerouslyPasteHTML(newValue, "silent");
    //     },
    // },
    methods: {
        refreshValue(newValue) {
            window.editor[this.name].clipboard.dangerouslyPasteHTML(newValue);
        },
        // insertTemplate() {
        //     this.insert(this.activeTemplate.content);
        // },
        // insertBetterTableIConInToolBar(){
        //   const iconButton = document.querySelector(".ql-table")
        //   iconButton.addEventListener('click',(evt)=>{
        //     this.table.insertTable(3, 3)
        //   })
        // },
        // fixTableStyle() {
        //     const tables = document.querySelectorAll(".quill-better-table");

        //     tables.forEach((tab) => {
        //         tab.querySelectorAll("td").forEach((td) => (td.style.border = "solid 1px black"));
        //         tab.style["border-collapse"] = "collapse";
        //     });
        // },
        async selectLocalImage() {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.click();

            // Listen upload local image and save to server
            input.onchange = async () => {
                const file = input.files[0];

                // file type is only image.
                if (/^image\//.test(file.type)) {
                    await this.saveToServer(file);
                } else {
                    console.warn("You could only upload images.");
                }
            };
        },

        /**
         * Step2. save to server
         *
         * @param {File} file
         */
        async saveToServer(file) {
            const fd = new FormData();
            fd.append("file", file);

            try {
                const url = `/uploads/reports/images`;
                const res = await axios.post(url, fd, {
                    headers: {"Content-Type": "multipart/form-data"},
                });
                this.uploadedUrl = `${process.env.VUE_APP_IMAGES_URL}/${res.data.file}`;
                this.insertToEditor();
            } catch (error) {}
        },

        /**
         * Insert image url to rich editor.
         *
         * @param {string} url
         */
        insertToEditor(url) {
            // push image url to rich editor.

            const range = window.editor[this.name].getSelection();
            window.editor[this.name].editor.insertEmbed(range.index, "image", this.uploadedUrl);
        },
        insert(link) {
            var selection = window.editor[this.name].getSelection(true);
            if (selection.length >= 1) {
                var text = window.editor[this.name].getText(selection.index, selection.length);
                window.editor[this.name].format("link", link);
            } else {
                window.editor[this.name].insertText(selection.index, link);
            }
            this.texts.forEach((e) => {
                // var selection = window.editor[this.name].getSelection(true);
            });
        },
        refreshData() {
            // TODO
            console.log("refresh links");
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../../mixins.scss";
* {
    @include scrollBar;
    &::-webkit-scrollbar {
        cursor: default;
    }
}
.editor {
    &--disabled {
        cursor: not-allowed;
        opacity: 0.6;
        * {
            pointer-events: none;
        }
    }
    @include field;
    padding: 0 !important;
    // overflow: hidden;
    box-sizing: border-box;
    .toolbar-additional {
        right: 0;
        margin-left: auto;
        svg {
            &:hover {
                color: var(--brand-color);
            }
        }
    }
    height: 100%;
    display: flex;
    flex-direction: column;
}
.qlbt-operation-menu {
    z-index: 33;
}
.insert-btn {
    display: flex;
    align-items: center;
    border: 1px dashed var(--brand-color);
    border-radius: 5rem;
    padding: 0.25rem 1rem !important;
    transition: var(--transition-mnml);
    cursor: pointer;
    outline: none;
    background-color: transparent;
    // margin: 0 0.5rem 0.5rem 0;

    &:hover {
        background-color: var(--brand-transparent);
    }

    p {
        margin: 0 0.6rem;
        color: var(--brand-color);
        font-size: 14px;
    }
    img {
        background-color: #bed4fc;
        padding: 0.3rem;
        border-radius: 100%;
        margin: 0.1rem;
    }
}
</style>
<style lang="scss">
.qeditor-wrapper {
    height: 100%;
    // .ql-editor {
    //     cursor: default;
    // }
}
.ql-container.ql-snow {
    overflow: hidden;
}
.ql-toolbar.ql-snow,
.ql-container.ql-snow {
    border-width: 0;
}
.ql-toolbar.ql-snow {
    // padding-right: 50px;
    border-bottom-width: 1px;
}
.multiselect {
    &__option {
        &--highlight {
            color: black;
        }
    }
}
</style>
