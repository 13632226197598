<template>
    <div class="tw-p-8 tw-bg-brand-area-color">
        <!-- Search Filters -->
        <search-bar v-model.trim="searchFilter" />

        <!-- list -->
        <neo-table :list="list" :headers="headers" :sublistProp="sublistProp" :sublistHeaders="sublistHeaders" :searchFilter="searchFilter" expandable v-bind="tableConfig" @update:page="changePage" @update:row="updateRow" @remove:row="removeRow" />
        <!-- Add New Repor Modal -->
        <edit-modal @submit="submitUpdate" @change="changeField" @search-change="searchChange" :data="updateData" :categories="computedOptions.categories" :sections="computedOptions.sections" ref="updateModal" :updateModal="updateModal" :validations="$v.updateData" :currentCase="updateSettings.case" />
        <modal-confirm ref="modal-confirm" />
    </div>
</template>

<script>
import SearchBar from "@/components/searchbar";
import Table from "@/components/table-expandable";
import {mapGetters, mapActions} from "vuex";
import titleMixin from "@/mixins/titleMixin";
import EditModal from "./editor.vue";
import ModalConfirm from "@/components/modal-confirm";
import {required} from "vuelidate/lib/validators";

const cases = {
    // 'general': 'category', // actually isn't used
    sub: "section",
};

export default {
    title() {
        return `Output Templates`;
    },
    mixins: [titleMixin],
    name: "report-builder-admin",
    components: {
        "neo-table": Table,
        "edit-modal": EditModal,
        "modal-confirm": ModalConfirm,
        SearchBar,
    },
    data() {
        return {
            list: [],
            headers: [
                {
                    text: "Category",
                    value: "category_name",
                    class: "tw-w-1/5",
                    search: true,
                },
                {
                    text: "Description",
                    value: "category_details",
                    class: "tw-w-3/5 tw-flex-grow",
                },
            ],
            sublistProp: "sections",
            // pageCount: 0,
            sublistHeaders: [
                {
                    value: "section_name",
                    search: true,
                },
                {
                    value: "details",
                },
            ],
            searchFilter: "",
            tableConfig: {
                isLoading: false,
                editable: {
                    general: false,
                    sub: true,
                },
            },
            updateSettings: {
                id: "",
                case: "new",
                category: null,
                section: null,
            },
            search: {
                category: "",
                section: "",
            },
            updateData: {
                category: {
                    value: null,
                    props: {},
                },
                section: {
                    value: null,
                    props: {},
                },
                template: {
                    value: "",
                    props: {},
                },
                guidance_notes: {
                    value: "",
                    props: {},
                },
            },
            options: {
                categories: [],
                sections: [],
            },
            updateModal: {
                loading: false,
                loadingGlobal: false,
            },
        };
    },
    async mounted() {
        await this.initPage();
    },
    computed: {
        ...mapGetters(["getAccountId"]),
        API() {
            return {
                get: this.getOutputTemplateAdminCategories,
                postCategory: this.postOutputTemplateCategory,
                putCategory: this.putOutputTemplateCategory,
                sections: this.getOutputTemplateAdminSections,
                section: this.getOutputTemplateSectionData,
                putSection: this.putOutputTemplateSection,
                addSection: this.addOutputTemplateSection,
                deleteCategory: this.deleteOutputTemplateCategory,
                deleteSection: this.deleteOutputTemplateSection,
            };
        },
        computedOptions() {
            let isNewCategory = this.search.category && !this.options.categories.find((el) => el.category_name === this.search.category);
            let categories = [...this.options.categories];
            if (isNewCategory)
                categories.unshift({
                    category_name: `Create new: ${this.search.category}`,
                    category_id: `Create new: ${this.search.category}`,
                    value: this.search.category,
                    custom: true,
                });
            let isNewSection = this.search.section && !this.options.categories.find((el) => el.section_name === this.search.section);
            let sections = [...this.options.sections];
            if (isNewSection)
                sections.unshift({
                    section_name: `Create new: ${this.search.section}`,
                    section_id: `Create new: ${this.search.section}`,
                    value: this.search.section,
                    custom: true,
                });
            categories = categories.filter((cat) => cat.category_name !== "Others");
            sections = sections.filter((sec) => sec.category_name !== "Others");
            return {
                categories,
                sections,
            };
        },
        changeCallbacks() {
            return {
                // category: (value) => {
                //     this.options.sections = [];
                //     if (value.custom) return;
                //     this.updateData.section.value = null;
                //     this.updateData.template.value = '';
                //     this.updateData.guidance_notes.value = '';
                //     this.options.sections = value.sections;
                // },
                section: async (value) => {
                    // Leave set values for fields (uncomment to reset):
                    if (value.custom) {
                        //     this.updateData.template.value = '';
                        //     this.updateData.guidance_notes.value = '';
                        //     this.$refs.updateModal.refreshSection();
                        return;
                    }
                    if (this.updateSettings.case === "section") return;
                    try {
                        this.updateModal.loadingGlobal = true;
                        // this.updateData.template.value = '';
                        // this.updateData.guidance_notes.value = '';
                        const response = await this.API.section({
                            category_id: value.category_id,
                            section_id: value.section_id,
                        });
                        this.updateData.template.value = response.template.template_details || "";
                        this.updateData.guidance_notes.value = response.guidance_notes || "";
                        this.$refs.updateModal.refreshSection();
                    } catch (error) {
                        this.$toast.error(this.getErrorText(error));
                    }
                    this.updateModal.loadingGlobal = false;
                },
            };
        },
    },
    validations() {
        const result = {};
        Object.entries(this.updateData).forEach(([key, field]) => {
            result[key] = {};
            result[key].value = {};
            if (!field.props.disabled) result[key].value.required = required;
        });
        return {
            updateData: result,
        };
    },
    methods: {
        ...mapActions("outputTemplates", ["getOutputTemplateAdminCategories", "getOutputTemplateSectionData", "postOutputTemplateCategory", "putOutputTemplateCategory", "putOutputTemplateSection", "addOutputTemplateSection", "deleteOutputTemplateCategory", "deleteOutputTemplateSection", "getOutputTemplateAdminSections"]),
        getErrorText(errorData) {
            return errorData.response.data?.detail || errorData.message || "Something went wrong";
        },
        async initPage({page} = {}) {
            this.tableConfig.isLoading = true;
            try {
                const response = await this.API.get({current_page: page});
                this.list = response.map((el, index) => ({
                    ...el,
                    id: el.category_id,
                    order: index,
                }));
                this.list = this.list.filter((l) => l.category_name !== "Others");
                this.options.categories = response;
            } catch (error) {
                this.$toast.error(this.getErrorText(error));
            }
            this.tableConfig.isLoading = false;
        },
        async changePage(page) {
            // await this.initPage({page});
        },
        async getSectionList() {
            this.options.sections = await this.API.sections();
        },
        async updateRow(payload = {}) {
            this.resetUpdateData(payload.type);
            switch (this.updateSettings.case) {
                case "section":
                    this.updateSettings.category = payload.parent;
                    this.updateSettings.section = payload.item;
                    this.updateData.category.value = payload.parent;
                    this.$set(this.updateData.category.props, "disabled", true);
                    this.$set(this.updateData.section.props, "type", "input");
                    this.updateData.section.value = payload.item.section_name;
                    this.options.sections = payload.parent.sections;

                    this.$refs.updateModal.showModal();

                    this.updateModal.loadingGlobal = true;
                    try {
                        const response = await this.API.section({
                            category_id: payload.parent.category_id,
                            section_id: payload.item.section_id,
                        });
                        this.updateData.template.value = response.template.template_details || "";
                        this.updateData.guidance_notes.value = response.guidance_notes || "";
                        this.$refs.updateModal.refreshSection();
                    } catch (error) {
                        this.$toast.error(this.getErrorText(error));
                    }

                    break;

                default:
                    // case 'new'
                    this.$refs.updateModal.showModal();
                    break;
            }
            await this.getSectionList();
            this.updateModal.loadingGlobal = false;
        },
        resetUpdateData(type) {
            this.$v.$reset();
            this.updateSettings = {
                id: "",
                case: cases[type] || "new",
                category: null,
                section: null,
            };
            this.updateData = {
                category: {
                    value: null,
                    props: {},
                },
                section: {
                    value: null,
                    props: {},
                },
                template: {
                    value: "",
                    props: {},
                },
                guidance_notes: {
                    value: "",
                    props: {},
                },
            };
            this.options.sections = [];
            this.search = {
                category: "",
                section: "",
            };
        },
        async submitUpdate() {
            this.$v.updateData.$touch();
            if (this.$v.updateData.$error) return;

            this.updateModal.loading = true;
            try {
                switch (this.updateSettings.case) {
                    case "new":
                        switch (this.updateData.category.value.custom) {
                            case true: // * new category
                                switch (this.updateData.section.value.custom) {
                                    case true: // * new section
                                        {
                                            const updateObject = {
                                                category_name: this.updateData.category.value.value,
                                                details: "",
                                                sections: [
                                                    {
                                                        section_name: this.updateData.section.value.value,
                                                        guidance_notes: this.updateData.guidance_notes.value,
                                                        template_data: {
                                                            html: "",
                                                            template_name: "",
                                                            template_details: this.updateData.template.value,
                                                        },
                                                    },
                                                ],
                                            };
                                            await this.API.postCategory(updateObject);
                                            this.$toast.success("Category created successfully");
                                        }

                                        break;

                                    default:
                                        // * esxisting section (but new for current category)
                                        {
                                            const updateObject = {
                                                category_name: this.updateData.category.value.value,
                                                details: "",
                                                sections: [
                                                    {
                                                        section_name: this.updateData.section.value.section_name,
                                                        guidance_notes: this.updateData.guidance_notes.value,
                                                        template_data: {
                                                            html: "",
                                                            template_name: "",
                                                            template_details: this.updateData.template.value,
                                                        },
                                                    },
                                                ],
                                            };
                                            await this.API.postCategory(updateObject);
                                            this.$toast.success("Category created successfully");
                                        }
                                        break;
                                }
                                break;

                            default:
                                // * existing category
                                switch (this.updateData.section.value.custom) {
                                    case true: // * new section
                                        {
                                            const updateObject = {
                                                section_name: this.updateData.section.value.value,
                                                guidance_notes: this.updateData.guidance_notes.value,
                                                template_data: {
                                                    html: "",
                                                    template_name: "",
                                                    template_details: this.updateData.template.value,
                                                },
                                            };
                                            await this.API.addSection({
                                                category_id: this.updateData.category.value.category_id,
                                                updateData: updateObject,
                                            });
                                        }
                                        break;

                                    default:
                                        // * esxisting section
                                        {
                                            const isSectionInCategory = !!this.updateData.category.value.sections?.find((el) => el.section_id === this.updateData.section.value.section_id);

                                            switch (
                                                isSectionInCategory // * check if existing section is already in this category
                                            ) {
                                                case true: // * update section
                                                    {
                                                        const updateObject = {
                                                            section_name: this.updateData.section.value.section_name,
                                                            guidance_notes: this.updateData.guidance_notes.value,
                                                            template_data: {
                                                                html: "",
                                                                template_name: "",
                                                                template_details: this.updateData.template.value,
                                                            },
                                                        };
                                                        await this.API.putSection({
                                                            category_id: this.updateData.category.value.category_id,
                                                            section_id: this.updateData.section.value.section_id,
                                                            updateData: updateObject,
                                                        });
                                                    }
                                                    break;

                                                default:
                                                    // * create new section from existing template
                                                    {
                                                        const updateObject = {
                                                            section_name: this.updateData.section.value.section_name,
                                                            guidance_notes: this.updateData.guidance_notes.value,
                                                            template_data: {
                                                                html: "",
                                                                template_name: "",
                                                                template_details: this.updateData.template.value,
                                                            },
                                                        };
                                                        await this.API.addSection({
                                                            category_id: this.updateData.category.value.category_id,
                                                            updateData: updateObject,
                                                        });
                                                    }
                                                    break;
                                            }
                                        }
                                        break;
                                }
                                this.$toast.success("Category updated successfully");
                                break;
                        }
                        break;

                    case "section":
                        {
                            const updateObject = {
                                section_name: this.updateData.section.value,
                                guidance_notes: this.updateData.guidance_notes.value,
                                template_data: {
                                    html: "",
                                    template_name: "",
                                    template_details: this.updateData.template.value,
                                },
                            };
                            await this.API.putSection({
                                category_id: this.updateSettings.category.category_id,
                                section_id: this.updateSettings.section.section_id,
                                updateData: updateObject,
                            });
                            this.$toast.success("Section updated successfully");
                        }
                        break;

                    default:
                        break;
                }
                this.$refs.updateModal.hideModal();
                await this.initPage();
            } catch (error) {
                this.$toast.error(this.getErrorText(error));
            }
            this.updateModal.loading = false;
        },
        async removeRow(payload) {
            const removeCase = cases[payload.type];
            try {
                switch (removeCase) {
                    // case 'category':
                    //     {
                    //         const confirm = await this.$refs['modal-confirm'].show({
                    //             title: "Are you sure you want to remove this category?",
                    //             autoclose: false,
                    //         });
                    //         if (confirm) {
                    //             await this.API.deleteCategory({
                    //                 category_id: payload.item.category_id,
                    //             });
                    //             this.$refs['modal-confirm'].close();
                    //             this.$toast.success("Category removed successfully");
                    //             await this.initPage();
                    //         }
                    //     }
                    //     break;
                    case "section":
                        {
                            const confirm = await this.$refs["modal-confirm"].show({
                                title: "Are you sure you want to remove this section?",
                                autoclose: false,
                            });
                            if (confirm) {
                                await this.API.deleteSection({
                                    category_id: payload.parent.category_id,
                                    section_id: payload.item.section_id,
                                });
                                // * Remove category if it become empty
                                if (payload.parent.sections.length === 1) {
                                    await this.API.deleteCategory({
                                        category_id: payload.parent.category_id,
                                    });
                                    this.$toast.success("Category removed successfully");
                                } else {
                                    this.$toast.success("Section removed successfully");
                                }
                                this.$refs["modal-confirm"].close();
                                await this.initPage();
                            }
                        }
                        break;

                    default:
                        break;
                }
            } catch (error) {
                this.$toast.error(error.message || "Something went wrong");
                this.$refs["modal-confirm"].close();
            }
        },
        isInvalid(fieldName) {
            return this.$v.updateData[fieldName].$invalid && this.$v.updateData.$error;
        },
        async changeField({field, value}) {
            this.updateData[field].value = value;
            if (this.changeCallbacks[field]) await this.changeCallbacks[field](value);
        },
        searchChange({field, value}) {
            this.search[field] = value;
        },
    },
};
</script>

<style scoped lang="scss"></style>
