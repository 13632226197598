<template>
    <div class="tw-mb-8 tw-pr-2 tw-border-0 tw-border-b-2 tw-border-solid tw-border-gray-200 tw-flex tw-flex-grow">
        <neo-input class="tw-w-full tw-border-0 tw-border-solid tw-border-b-2 tw-border-gray-200" :shadow="false" :placeholder="placeholder" v-model.trim="searchFilter" :border="0" bg="var(--brand-area-color)" margin="0" icon="search" />
    </div>
</template>

<script>
import Input from "@/components/input";

export default {
    name: "search-bar",
    components: {
        "neo-input": Input,
    },
    props: {
        placeholder: {
            type: String,
            default: "Search",
        },
        value: String,
    },
    computed: {
        searchFilter: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("input", newValue);
            },
        },
    },
};
</script>

<style></style>
