<template>
    <neo-modal-content ref="updateModal" class="updateModal" name="updateModal" :title="'Add an output template'" :submitButtonText="buttonLabel[currentCase]" width="90%" :maxWidth="1400" @submit="submitModal" v-bind="updateModal">
        <template #content>
            <div class="updateModal__content tw-py-4">
                <div class="updateModal__content__row tw-flex">
                    <div class="section tw-w-1/3">
                        <div class="section__header">Category name</div>
                        <!-- <neo-input
                            v-if="data.category.props.type === 'input'"
                            :value="data.category.value"
                            class="field"
                            :class="{'field--invalid': isInvalid('category')}"
                            placeholder="Enter category name"
                            bg="white"
                            margin="0"
                            :style="{width: '100%'}"
                            @input="changeField($event, 'category')"
                        /> -->
                        <multiselect :class="{invalid: isInvalid('category')}" :value="data.category.value" @select="changeField($event, 'category')" @search-change="searchChange($event, 'category')" :options="categories" track-by="category_id" label="category_name" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Select or create new" :disabled="data.category.props.disabled">
                            <template #noResult>
                                <span>Type to create a new category</span>
                            </template>
                            <template #noOptions>
                                <span>Type to create a new category</span>
                            </template>
                        </multiselect>
                    </div>
                    <div class="section tw-w-1/3">
                        <div class="section__header">Section name</div>
                        <neo-input v-if="data.section.props.type === 'input'" :value="data.section.value" class="field" :class="{'field--invalid': isInvalid('section')}" placeholder="Enter section name" bg="white" margin="0" :style="{width: '100%'}" @input="changeField($event, 'section')" />
                        <multiselect v-else :class="{invalid: isInvalid('section')}" :value="data.section.value" @select="changeField($event, 'section')" @search-change="searchChange($event, 'section')" :options="sections" track-by="section_id" label="section_name" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Select or create new" :disabled="data.section.props.disabled">
                            <template #noResult>
                                <span>Type to create a new section</span>
                            </template>
                            <template #noOptions>
                                <span>Type to create a new section</span>
                            </template>
                        </multiselect>
                    </div>
                </div>
                <div class="updateModal__content__row tw-flex">
                    <div class="section tw-w-full">
                        <div class="section__header">Template</div>
                        <text-editor :invalid="isInvalid('template')" v-model="template" :styles="{height: '190px'}" :disabled="data.template.props.disabled" name="template" ref="template" />
                    </div>
                </div>
                <div class="updateModal__content__row tw-flex">
                    <div class="section tw-w-full">
                        <div class="section__header">Guidance notes</div>
                        <text-editor :invalid="isInvalid('guidance_notes')" v-model="guidance_notes" :styles="{height: '190px'}" :disabled="data.guidance_notes.props.disabled" name="guidance_notes" ref="guidance_notes" />
                    </div>
                </div>
            </div>
        </template>
    </neo-modal-content>
</template>

<script>
import Modal from "@/components/modal-content";
// import noteCard from "@/containers/cards/note";
import RichTextEditor from "@/components/rich-text-editor/index.vue";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";

const Input = () => import("@/components/input");
const Multiselect = () => import("vue-multiselect");

export default {
    name: "report-builder-row-editor",
    components: {
        "neo-input": Input,
        "neo-modal-content": Modal,
        "text-editor": RichTextEditor,
        multiselect: Multiselect,
    },
    props: {
        validations: {
            type: Object,
            default: () => ({}),
        },
        data: {
            type: Object,
            default: () => ({}),
        },
        updateModal: {
            type: Object,
            default: () => ({}),
        },
        categories: {
            type: Array,
            default: () => [],
        },
        sections: {
            type: Array,
            default: () => [],
        },
        selectedCategoryId: {
            type: String,
            default: () => "",
        },
        currentCase: {
            type: String,
            default: () => "new",
        },
    },
    data() {
        return {
            modalTitle: {
                new: "Add an output template",
                category: "Update category",
                section: "Update section",
                "add-section": "Add new section",
            },
            buttonLabel: {
                new: "Add new",
                category: "Update",
                section: "Update",
                "add-section": "Add new",
            },
        };
    },
    computed: {
        template: {
            get() {
                return this.data.template.value;
            },
            set(newValue) {
                this.changeField(newValue, "template");
            },
        },
        guidance_notes: {
            get() {
                return this.data.guidance_notes.value;
            },
            set(newValue) {
                this.changeField(newValue, "guidance_notes");
            },
        },
    },
    methods: {
        showModal() {
            this.$refs.updateModal.showModal();
        },
        hideModal() {
            this.$refs.updateModal.hideModal();
        },
        submitModal() {
            this.$emit("submit");
        },
        changeField(value, field) {
            this.$emit("change", {field, value});
        },
        searchChange(value, field) {
            this.$emit("search-change", {field, value});
        },
        isInvalid(fieldName) {
            return this.validations[fieldName].$invalid && this.validations.$error;
        },
        refreshSection() {
            this.$refs.template.refreshValue(this.data.template.value);
            this.$refs.guidance_notes.refreshValue(this.data.guidance_notes.value);
        },
    },
};
</script>

<style scoped lang="scss">
@import "../../mixins.scss";
.updateModal {
    &__content {
        padding-top: 10px;
        padding-bottom: 35px;
        &__row {
            &:not(:last-child) {
                margin-bottom: 25px;
            }
            .section {
                &:not(:last-child) {
                    margin-right: 3rem;
                }
                &__header {
                    margin-bottom: 9px;
                    color: #939393;
                }
                .field {
                    @include field;
                }
            }
        }
    }
}
</style>
<style lang="scss">
@import "../../mixins.scss";
.multiselect {
    &--disabled {
        cursor: not-allowed;
        pointer-events: initial;
        * {
            pointer-events: none;
        }
    }
    &__tags {
        @include field;
    }
    &__select {
        height: calc(100% - 2px) !important;
        top: 1px !important;
        right: 1px !important;
    }
}
</style>
